<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" xl="4" class="pa-3 d-flex flex-column">
        <v-card class="pa-2 elevation-5 flex d-flex flex-column">
          <v-card-title>
            {{ $t("reports.ppp") }}
          </v-card-title>
          <v-card-text class="flex">
            <Doughnut v-if="loaded" :chartdata="pppChart"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="4" class="pa-3 d-flex flex-column">
        <v-card class="pa-2 elevation-5 flex d-flex flex-column">
          <v-card-title>
            {{ $t("reports.sumppp") }}
          </v-card-title>
          <v-card-text class="flex">
            <Doughnut v-if="loaded" :chartdata="sppChart"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="4" class="pa-3 d-flex flex-column">
        <v-card class="pa-2 elevation-5 flex d-flex flex-column">
          <v-card-title>
            {{ $t("reports.cppp") }}
          </v-card-title>
          <v-card-text class="flex">
            <Doughnut v-if="loaded" :chartdata="cbsChart"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="4" class="pa-3 d-flex flex-column">
        <v-card class="pa-2 elevation-5 flex d-flex flex-column">
          <v-card-title>
            {{ $t("reports.cppt") }}
          </v-card-title>
          <v-card-text class="flex">
            <Doughnut v-if="loaded" :chartdata="cbtChart"/>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" xl="4" class="pa-3 d-flex flex-column">
        <v-card class="pa-2 elevation-5 flex d-flex flex-column">
          <v-card-title>
            {{ $t("reports.ispb") }}
          </v-card-title>
          <v-card-text class="flex">
            <Doughnut v-if="loaded" :chartdata="isbbChart"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Doughnut from "../_charts/doughnut";

export default {
  name: "reportview",
  components: { Doughnut },
  data: () => ({
    loaded: false,
    colors: ["#cddc39", "#7D98A1", "#5E6572", "#A9B4C2", "#EFF2F0", "#1C2321"],
    pppChart: null,
    sppChart: null,
    cbsChart: null,
    cbtChart: null,
    isbbChart: null
  }),
  async mounted() {
    this.loaded = false;
    this.$store.dispatch("loading", true);

    axios
      .get(`/api/reports/projects`)
      .then(response => {
        this.pppChart = {
          datasets: [
            {
              data: response.data.projectsByOwner.map(o => o.total),
              backgroundColor: this.colors
            }
          ],
          labels: response.data.projectsByOwner.map(o => o.name)
        };

        this.sppChart = {
          datasets: [
            {
              data: response.data.volumeByOwner.map(o => o.volume || 0),
              backgroundColor: this.colors
            }
          ],
          labels: response.data.projectsByOwner.map(o => o.name)
        };

        this.cbsChart = {
          datasets: [
            {
              data: response.data.countByState.map(o => o.value || 0),
              backgroundColor: this.colors
            }
          ],
          labels: response.data.countByState.map(o => this.$t("projects.types." + o.position))
        };

        this.cbtChart = {
          datasets: [
            {
              data: response.data.countyByType.map(o => o.value || 0),
              backgroundColor: this.colors
            }
          ],
          labels: response.data.countyByType.map(o => o.name)
        };

        this.isbbChart = {
          datasets: [
            {
              data: response.data.imvestementSumByBank.map(o => o.volume || 0),
              backgroundColor: this.colors
            }
          ],
          labels: response.data.imvestementSumByBank.map(o => o.name)
        };

        this.loaded = true;
        this.$store.dispatch("loading", false);
      })
      .catch(e => {
        console.log(e);
        this.$store.dispatch("loading", false);
      });
  }
};
</script>
