<template>
  <DefaultLayout>
    <v-layout>
      <v-flex>
        <Reports />
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
// @ is an alias to /src
import DefaultLayout from "@/layouts/default.vue";

import Reports from "@/components/reports/reportview";

export default {
  name: "home",
  computed: {},
  components: {
    DefaultLayout,
    Reports,
  },
};
</script>
